<template>
    <div v-if="showDeleteSessionModal" class="modal">
		<div class="modal-content">
			<div v-if="recurrence === 'ONCE'">
				<p class="delete-session-text-intro">Etes vous sûr de vouloir supprimer la séance ? </p>
				<div class="modal-buttons delete-session-buttons">
					<button name="cancel" class="cancel" @click="showDeleteSessionModal = false">Annuler</button>
					<button name="submit" @click="deleteSession" class="button-danger">OK</button>
				</div>
			</div>
			<div v-else>
				<p class="delete-session-text-intro">Supprimer l'événement périodique</p>
				<div class="delete-session-radio-selection">
					<input type="radio" id="one" value="once" v-model="deletionType"><label for="one">Cet évènement</label>
				</div>
				<div class="delete-session-radio-selection">
					<input type="radio" id="two" value="all" v-model="deletionType"><label for="two">Cet évènement et les suivants</label>
				</div>
				<div class="modal-buttons delete-session-buttons">
					<button name="cancel" class="cancel" @click="showDeleteSessionModal = false">Annuler</button>
					<button name="submit" @click="deleteSessions" class="button-danger">OK</button>
				</div>
			</div>
		</div>
	</div>

	<div v-if="askModifyWithRecurrence" class="modal">
		<div class="modal-content">
			<div>
				<p class="delete-session-text-intro">Modifier l'événement périodique</p>
				<div class="delete-session-radio-selection">
					<input type="radio" id="one" value="once" v-model="modificationType"><label for="one">Cet évènement</label>
				</div>
				<div class="delete-session-radio-selection">
					<input type="radio" id="two" value="all" v-model="modificationType"><label for="two">Cet évènement et les suivants</label>
				</div>
				<div class="modal-buttons delete-session-buttons">
					<button name="cancel" class="cancel" @click="askModifyWithRecurrence = false">Annuler</button>
					<button name="submit" @click="editSession()" class="button">OK</button>
				</div>
			</div>
		</div>
	</div>

	<div>
		<form class="apform">
			<div v-if="isEdit" class="modal-title-with-delete">
				<h3 class="beforeDelete">Détails de la séance</h3>
				<div class="delete-event" v-if="validated">
					<font-awesome-icon icon="trash-alt" class="trash pointer" @click="showDeleteSessionModal = true"/>
				</div>
			</div>
			<div v-else>
				<h3>Ajouter une séance</h3>
			</div>
			<div v-if="errors.length" class="errorMessages">
				<div>Champs requis</div>
				<ul>
					<li v-for="(error, errorIndex) in errors" v-bind:key="errorIndex">{{ error }}</li>
				</ul>
			</div>
			<div v-if="isOfferUltime">
				<div v-if="therapists.length > 1"> 
					<label>Practicien</label> 
					<select
						class="zoo-input select-patient-club-animal"
						name="therapist"
						id="therapist-select"
						v-model="selectedTherapist"
						tabindex="1"
					>
						<option disabled :value="{}">Choisissez</option>
						<option
							v-for="therapist in therapists"
							:value="therapist"
							:key="therapist"
						>
						{{ therapist.lastname }} {{ therapist.firstname !== null ? therapist.firstname : therapist.email }}
						</option>
					</select>
				</div>
				<div v-else>
					Vous créez une session pour {{selectedTherapist.firstname}} {{selectedTherapist.lastname}}
				</div>
			</div>
			<div class="session-section">
				<h3>Date</h3>
				<div class="date-time-input">
					<div>
						<label for="startDate">Jour</label>	
						<input type="date" class="event-date" tabindex="1" name="startDate" v-model="startDate" placeholder="Jour de la séance" @change="updateRecurrences" lang="FR" required autofocus/>
						<span v-if="errors.day" class="errorMessage">{{errors.day}}</span>
					</div>
					<div>
						<label for="startTime">De</label>
						<input type="time" class="event-date" tabindex="2" name="startTime" v-model="startTime"  @change="updateEndTime" placeholder="Heure de début" required/>
						<span v-if="errors.startTime" class="errorMessage">{{errors.startTime}}</span>
					</div>
					<div>
						<label for="endTime">A</label>
						<input type="time" class="event-date" tabindex="3" name="endTime" v-model="endTime" placeholder="Heure de fin" required/>
						<span v-if="errors.endTime" class="errorMessage">{{errors.endTime}}</span>
					</div>

				</div>
				<span v-if="errors.date" class="errorMessage">{{errors.date}}</span>
			</div>	
			<div class="session-section">
				<h3>Fréquence</h3>
				<div v-if="isEdit === false" class="flex">
					<select name="recurrence" v-model="recurrence" tabindex="4" @change="updateEndDate" required class="select-patient-club-animal">
							<option v-for="r in recurrences" :key="r.description" :value="r.value" :selected="recurrence === r.value">
								{{ r.description }}
							</option>
					</select>
					<div v-if="recurrence !== 'ONCE'" class="recurrence">
						<label for="endDate">Jusqu'au</label>
						<input name="endDate" type="date" tabindex="5" v-model="endDate" placeholder="Date de fin"/>
						<span v-if="errors.endDate" class="errorMessage">{{errors.endDate}}</span>
					</div>
				</div>
				<div v-else>{{ displayRecurrence() }}</div>
			</div>
			<div class="session-section">
				<h3>Séance</h3>
				<div id="work-with-animal">
					<label>Travail de l'animal seulement</label>
					<div id="toggle-div">
						<input type="checkbox" id="toggle_input" tabindex="6" v-model="withAnimalOnly">
						<label for="toggle_input" class="toggle_label">Toggle</label>
					</div>
				</div>
				
				<div id="session-type-container" v-if="!withAnimalOnly && sessionType !== {}">
					<label>Type de séance</label>
					<select
						name="therapist"
						v-model="sessionType"
						tabindex="7"
						@change="changeSessionPrice"
						class="zoo-input select-patient-club-animal"
						>
							<option disabled :value="{}">Choisissez</option>
							<option
								v-for="sessionType in sessionTypes"
								:key="sessionType"
								:value="sessionType"
							>
							{{ sessionType.name }}
							</option>
					</select>
				</div>

				<div v-if="!withAnimalOnly">
					<label for="patient-select">Patient</label>
					<div v-if="!validated">
						{{ selectedPatient.individual.lastname }} {{ selectedPatient.individual.firstname }} - {{ selectedPatient.email }}
					</div>
					<multiselect class="select-patient-club-animal" 
								v-model="selectedPatient" 
								:options="$store.state.patients" 
								placeholder="Sélectionner" 
								label="patients" 
								:custom-label="customPatientLabel" 
								track-by="id" 
								@select="checkPatientType" 
								selectLabel="" 
								deselectLabel=""
								tabindex="8">
					</multiselect>
					<span v-if="errors.patient" class="errorMessage">{{errors.patient}}</span>
				</div>
				<div>
					<label for="animal-select">Animal</label>
					<multiselect class="select-patient-club-animal" 
								v-model="selectedAnimals" 
								:options="$store.state.animals" 
								:multiple="true" 
								:close-on-select="false" 
								placeholder="Aucun" 
								:preserve-search="true" 
								label="name" 
								track-by="id" 
								@select="getAnimalActivity" 
								@remove="removeAnimalActivity" 
								selectLabel="" 
								deselectLabel=""
								tabindex="9">
					</multiselect>
				</div>
				<div>
					<label for="club-select">Lieu</label>
					<select
						class="zoo-input select-patient-club-animal"
						name="clubs"
						id="club-select"
						v-model="selectedClub"
						tabindex="10"
						@change="changeTaxesValue"
					>
						<option disabled :value="{}">Aucun</option>
						<option
							v-for="(club, index) in $store.state.clubs"
							v-bind:value="club"
							v-bind:key="index"
						>
							{{ club.name }}
						</option>
					</select>
				</div>
				
				<div v-if="!withAnimalOnly && selectedPatient && patientsTypes[selectedPatient.type] === patientsTypes.FIRM">
					
					<div id="select-corporate-participants">
						<div>
							<label for="corporate-patient-origin-select">Employés</label>
							<select
								class="zoo-input select-patient-club-animal select-multiple-patients"
								name="patients"
								id="corporate-patient-origin-select"
								v-model="selectedCorporatePatientsOrigin"
								tabindex="11"
								multiple
								@dblclick="addSelected"
							>
								<option disabled :value="{}">Choisissez</option>
								<option
									v-for="(patient, index) in corporatePatientsOrigin"
									v-bind:value="patient"
									v-bind:key="index"
								>
									{{ `${patient.firstname} ${patient.lastname}` }}
								</option>
							</select>
						</div>
						<div>
							<button @click="addSelected">&rsaquo;</button>
							<button @click="addAll">&raquo;</button>
							<button @click="removeSelected">&lsaquo;</button>
							<button @click="removeAll">&laquo;</button>
						</div>
						<div>
							<label for="corporate-patient-destination-select">Employés sélectionnés</label>
							<select
								class="zoo-input select-patient-club-animal select-multiple-patients"
								name="corporate-patient-destination-select"
								id="patient-select"
								v-model="selectedCorporatePatientsDestination"
								tabindex="12"
								multiple
								@dblclick="removeSelected"
							>
								<option
									v-for="(patient, index) in corporatePatientsDestination"
									v-bind:value="patient"
									v-bind:key="index"
								>
									{{ `${patient.firstname} ${patient.lastname}` }}
								</option>
							</select>
						</div>
					</div>
				</div>
				
				<div v-if="nbHoursLeft.length > 0" class="nb-hours-left-container">
					<p><font-awesome-icon icon="triangle-exclamation" style="margin-right: 0.5rem"/>Nombre d'heures libres restantes cette semaine par animal : </p>
					<ul>
						<li v-for="nbHoursLeftByAnimal in nbHoursLeft" :key="nbHoursLeftByAnimal">{{ nbHoursLeftByAnimal.animalName }} : {{ nbHoursLeftByAnimal.hours }}</li>
					</ul>
				</div>		
				
				<div v-if="isEdit" style="display: flex">
					<input name="paid" type="checkbox" tabindex="13" v-model="paid" @change="updateSessionPayment(id)"/>			
					<label for="paid" style="margin-left: 1rem">Payée</label>
				</div>
				<div data-cy="session-taxes" style="display: flex">
					<div>
						<label for="price">Prix (H.T)</label>
						<input data-cy="session-taxes-price" name="price" type="number" tabindex="14" step=0.01 v-model="price"/>
					</div>
					<div>
						<label for="roomRent">Location club</label>
						<input data-cy="session-taxes-roomRent" name="roomRent" type="number" tabindex="15" step=0.01 v-model="roomRent" @blur="updatetaxesPaidByPatient"/>
					</div>
					<div>
						<label for="animalRent">Location animal</label>
						<input data-cy="session-taxes-animalRent" name="animalRent" type="number" tabindex="16" step=0.01 v-model="animalRent" @blur="updatetaxesPaidByPatient"/>
					</div>
				</div>
				<div style="display: flex">
					<div style="width: 18rem;">
					</div>
					<div style="display: flex; width: 16rem; height: 2.6rem;">
						<input name="checkTaxesPaidByPatient" data-cy="check-taxes-paid-by-patient" style="margin-right:0.5rem;" type="checkbox" tabindex="17" v-model="checkTaxesPaidByPatient" @change="updatetaxesPaidByPatient"/>
						<label for="checkTaxesPaidByPatient">Le patient paie la location</label>
					</div>
					<div v-if="checkTaxesPaidByPatient" style="margin-left: 2rem; margin-bottom: 0;">
						<label for="taxesPaidByPatient">Payé directement au club par le patient</label>
						<input name="taxesPaidByPatient" type="number" tabindex="18" step=0.01 v-model="taxesPaidByPatient" style="width: 9rem;"/>
					</div>
				</div>
				<div v-if="isEdit">
					<div v-if="answers.length > 0">
						<h3>Questions posées lors du 1er rendez-vous</h3>
						<div>
							<div v-for="answer in answers" :key="answer" class="question-answer-content">
								<h4>{{ answer.question }}</h4>
								<p>{{ answer.answer }}</p>
							</div>
						</div>
					</div>
					<div v-if="isNotOfferStandard">
						<div>
							<div>
								<audio-recorder :sessionId="id" @receiveNewAudioComment="updateAudioComments"/>
							</div>
							<div v-if="audioComments.length > 0">
								<h4>Liste des commentaires audio</h4>
								<div v-for="(audioComment, index) in audioComments"
										v-bind:key="index"
										class="flex-container">
									<div class="play-icon-container"><font-awesome-icon icon="play" @click="playAudio(audioComment)"/></div>
									<div class="date-audio-container">{{getDateFromName(audioComment.name)}}</div>
									<div class="delete-sound-icon-container" @click="deleteAudioContent(audioComment)">
										<font-awesome-layers class="fa-lg times-circle-layer-style">
											<font-awesome-icon icon="fa-regular fa-circle" class="times-circle-style" />
											<font-awesome-icon icon="fa-times" transform="shrink-4" class="times-circle-style" />
										</font-awesome-layers>
									</div> 			
								</div>
							</div>
						</div>
					</div>
					
					<textarea type="text" tabindex="19" v-model="comments" class="text-area" placeholder="Commentaires" rows="10" cols="58"/>
				</div>
			</div>
			<div class="flex session-section">
				<button v-if="validated" name="cancel" type="reset" tabindex="20" class="cancel" @click="backToCalendar">Annuler</button>
				<button v-if="!validated" name="cancel" type="reset" tabindex="21" class="cancel" @click="backToCalendar">Retour</button>
				<div v-if="!validated" class="flex-container">
					<button name="submit" type="submit" tabindex="22" @click="callCancelSession">
						<div v-if="loadingCancel" class="loader init-bottom-margin"></div>
						<span>Annuler la séance</span>
					</button>
					<button name="submit" type="submit" tabindex="23" @click="callValidateSession">
						<div v-if="loading" class="loader init-bottom-margin"></div>
						<span>Valider</span>
					</button>
				</div>
				<button v-if="(isEdit && validated)" name="submit" type="submit" tabindex="24" @click="showOrEditSession">Enregistrer</button>
				<button v-if="(!isEdit && validated)" name="submit" type="submit" tabindex="24	" @click="addSession">Ajouter</button>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { PATIENTS_TYPES } from '../../../services/patientTypes';
import { OFFERS } from '../../../services/offers';
import { ROLES } from '../../../services/roles';
import AudioRecorder from '../audio/AudioRecorder.vue';
import { getAudioCommentsBySessionId, getAudioCommentBySessionId, deleteAudioCommentById, validateSession, cancelSession, updatePaymentSession } from '../../../services/session'
import { getTherapistSessionTypes } from '../../../services/therapists'
import { getTherapists } from '../services/team'
import { getQuestionsAndAnswersBySessionId } from '../services/questions'

import _ from 'lodash';

export default ({
	// eslint-disable-next-line
    name: 'Session',
	metaInfo: {
		title: 'Session',
		meta: [
			{ vmid: 'description', name:'description', content: 'ajout de séance'}
		]	
	},
	components: {
		AudioRecorder
	},
    data() {
		return {
			loading: false,
			loadingCancel: false,
			isOfferUltime: false,
			isTherapist: false,
			therapists: [],
			selectedTherapist: {},
			id: '',
			deletionType: 'once',
			startDate: '',
			endDate: '',
			startTime: '',
			endTime: '',
			selectedPatient: {},
			selectedAnimals: [],
			selectedClub: {},
			price: 0,
			roomRent: 0,
			animalRent: 0,
			taxesPaidByPatient: 0,
			checkTaxesPaidByPatient: false,
			paid: false,
			validated: true,
			patientPresent: true,
			nbHoursLeft: [],
			comments: '',
			audioComments: [],
			calendarEvents: [],
			errors: {},
			currentState: false,
			withAnimalOnly: false,
			patientsTypes: PATIENTS_TYPES,
			corporatePatientsOrigin: [],
			selectedCorporatePatientsOrigin: [],
			corporatePatientsDestination: [],
			selectedCorporatePatientsDestination: [],
			isEdit: false,
			showDeleteSessionModal: false,
			isNotOfferStandard: false,
			recurrences : [
				{
					description : 'une seule fois',
					value : 'ONCE'
				},
				{
					description : 'tous les jours',
					value : 'DAY'
				},
				{
					description : 'toutes les semaines le ',
					value : 'WEEK'
				},
				{
					description : 'toutes les deux semaines le ',
					value : 'TWO_WEEKS'
				},
				{
					description : 'tous les jours de la semaine',
					value : 'DAY_OF_WEEK'
				}
			],
			sessionTypes: [],
			sessionType: {},
			recurrence : {},
			weekday : ["dimanche","lundi","mardi","mercredi","jeudi","vendredi","samedi"],
			options: ['list', 'of', 'options'],
			askModifyWithRecurrence: false,
			modificationType: 'once',
			answers: []
		};
	},
	computed: {
		...mapState(['sessions', 'patients', 'animals', 'clubs', 'user']),
	},
	created() {
		this.offerName = this.$store.getters.user.tenant.offer
		this.isOfferUltime = this.offerName === 'ULTIME'
		if (this.isOfferUltime)
			this.callGetTherapists()

		this.isTherapist = this.$store.getters.roles === ROLES.THERAPIST.value;
		this.recurrence = this.recurrences[0].value;
		if (!this.$store.state.animals || this.$store.state.animals.length === 0)
			this.$store.dispatch('initAnimals');
		if (!this.$store.state.patients || this.$store.state.patients.length === 0)
			this.$store.dispatch('initPatients');
		if (!this.$store.state.clubs || this.$store.state.clubs.length === 0)			
			this.$store.dispatch('initClubs');
	},
	mounted() {
		if (this.$route.params.id) {
			this.isEdit = true
			this.id = this.$route.params.id
			this.getSessionDetails()
		}
		[this.startDate, this.startTime ] = this.getInitialDate()
		this.updateEndTime()
		this.updateRecurrences()
		this.isNotOfferStandard = this.$store.state.user.tenant.offer.toLocaleUpperCase() !== OFFERS.STANDARD.toString().toLocaleUpperCase()
		this.getSessionTypeByTherapist()
	},
	methods: {
		updateSessionPayment(sessionId) {
            updatePaymentSession(sessionId, this.paid).then(() => {
                this.$toast.success(`Information mises à jour`);
            }).catch(() => {
                this.$toast.error(`Mise à jour paiement impossible`);
            })
        },
		customPatientLabel(patient) {
			if (Object.keys(patient).length > 0)
				if (patient.type === PATIENTS_TYPES.FIRM.value)
					return patient.firm.companyName
				else
					return `${patient.individual?.lastname} ${patient.individual?.firstname}`
		},
		updateRecurrences() {
			const d = new Date(this.startDate);
			let day = this.weekday[d.getDay()];
			this.recurrences[2].description = 'toutes les semaines le ' + day;
			this.recurrences[3].description = 'toutes les deux semaines le ' + day;
		},
		getSessionTypeByTherapist(){
			getTherapistSessionTypes(this.$store.state.user.id).then(response => {
				this.sessionTypes = response
				this.sessionType = this.sessionTypes[0]
				this.price = this.sessionType.defaultSessionPrice
			});
		},
		getSessionDetails() {
			this.$store.dispatch({
				type: 'getSession',
				id: this.id,
			}).then( sessionDetails => {
				this.startDate = sessionDetails.date;
				this.startTime = sessionDetails.startTime;
				this.endTime = sessionDetails.endTime;
				this.recurrence = sessionDetails.recurrence;
				this.selectedAnimals = sessionDetails.animals
				this.selectedPatient = sessionDetails.patient
				this.selectedClub = sessionDetails.club
				this.paid = sessionDetails.paid
				this.validated = sessionDetails.validated
				this.price = sessionDetails.price
				this.roomRent = sessionDetails.roomRent
				this.animalRent = sessionDetails.animalRent
				this.taxesPaidByPatient = sessionDetails.taxesPaidByPatient
				if (this.taxesPaidByPatient > 0.0) {
					this.checkTaxesPaidByPatient = true
				}
				this.patientPresent = sessionDetails.patientPresent
				this.withAnimalOnly = sessionDetails.withAnimalOnly
				this.comments = sessionDetails.comments
				this.corporatePatientsDestination = sessionDetails.selectedCorporatePatients ? sessionDetails.selectedCorporatePatients : []
				this.checkPatientType(this.selectedPatient)
				this.sessionType = sessionDetails.sessionType
				if (this.isNotOfferStandard) {
					getAudioCommentsBySessionId(this.id).then(response => {
						this.audioComments = response;
					}).catch(() => {});
				}
				getQuestionsAndAnswersBySessionId(this.id).then((response) => {
					this.answers = response
				})
				if (this.isOfferUltime) {
					this.selectedTherapist = _.find(this.therapists, el => el.id === sessionDetails.therapistId)
				}
			});
		},
		checkPatientType(patient) {
			this.selectedPatient = patient
			if (patient && PATIENTS_TYPES[patient.type] === PATIENTS_TYPES.FIRM) {
				this.$store.dispatch({
					type: 'getPatientDetails',
					id: patient.id
				}).then(response => {
					this.corporatePatientsOrigin = this.sortedPatients(response.firm.corporatePatients, 'desc', 'firstname');
					let patientsNotAlreadyPresent = this.corporatePatientsOrigin.filter((value) => {
						return (value.type === undefined || PATIENTS_TYPES[value.type] === PATIENTS_TYPES.INDIVIDUAL) && this.corporatePatientsDestination.find(p => p.id === value.id) === undefined;
					})
					this.corporatePatientsOrigin = patientsNotAlreadyPresent;
				});
			} else {
				this.corporatePatientsOrigin = [];
			}
		},
		sortedPatients(patients, sortDirection, sortCriteria) {
            if (patients) {
                return patients.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === sortDirection) modifier = -1;
                    if(a[sortCriteria] < b[sortCriteria]) return -1 * modifier;
                    if(a[sortCriteria] > b[sortCriteria]) return 1 * modifier;

                    return 0;
                });
            }
        },
		callGetTherapists() {
			console.log(this.$store.getters)
			getTherapists().then(team => {
				this.therapists = team.members
				if (this.$store.getters.roles === ROLES.THERAPIST.value || this.$store.getters.roles === ROLES.ADMIN.value) {
					this.selectedTherapist = _.find(this.therapists, el => el.id === this.$store.getters.user.id);
				} else if (this.therapists.length >= 1) {
					this.selectedTherapist = this.therapists[0];
				}
			})
		},
		getInitialDate() {
			let initialDate = new Date();
			if (this.$router.options.history.state && this.$router.options.history.state.initialDate !== undefined) {
				initialDate = new Date(this.$router.options.history.state.initialDate );
			}
			return [initialDate.toISOString().substring(0,10), initialDate.toTimeString().substring(0,5)];
		},
        checkForm() {		
			if (this.startDate !== '' 
			&& this.startTime !== '' && this.endTime !== '') {
                return true;
            }

            this.errors = {}

            if (this.startDate === '') 
                this.errors.day = 'Obligatoire'
            
			if (this.startTime === '')
				this.errors.startTime = 'Obligatoire'
			if (this.endTime === '')
                this.errors.endTime = 'Obligatoire'
            
			if (this.recurrence !== 'ONCE' && this.isEdit === false && this.endDate === '')
				this.errors.endDate = 'obligatoire si récurrence';

            return false;	
        },
		addSession(e) {
            e.preventDefault();
			if (this.checkForm()) {
                this.$store.dispatch({
                    type: 'addSession',
                    session: {
                        startDate: this.startDate,
						startTime: this.startTime,
                        endTime: this.endTime,
                        endDate: this.endDate,
						animalIds: this.selectedAnimals.map(a => a.id),
                        clubId: this.selectedClub ? this.selectedClub.id : null,
                        patientId: this.selectedPatient.id,
						price: this.price,
						roomRent: this.roomRent,
						animalRent: this.animalRent,
						taxesPaidByPatient: this.checkTaxesPaidByPatient ? this.taxesPaidByPatient : 0.0,
						patientPresent: this.patientPresent,
						paid: this.paid,
						validated: true,
						withAnimalOnly: this.withAnimalOnly,
						therapistId: this.selectedTherapist.id,
						selectedCorporatePatients: this.corporatePatientsDestination ? this.corporatePatientsDestination.map((obj) => obj.id) : null,
						recurrence: this.recurrence,
						sessionType: this.sessionType.id
                    },
                }).then(() => {
                    this.$toast.success(`Séance correctement ajoutée`);
					this.backToCalendar();
                }).catch((error) => {
					if (error.response.data.errorCode === 'E113') {
						this.errors.date = 'La date de fin doit être supérieure à la date de début.'
					}
				});
            }
		},
		callValidateSession(e) {
			e.preventDefault()
			this.loading=true
			validateSession(this.id).then(() => {
				this.validated = true
				this.loading=false
				this.editSession(e)
			}).catch(() => {
				this.loading=false
				this.$toast.error('Impossible de valider la séance')
			})
		},
		callCancelSession(e) {
			e.preventDefault()
			this.loadingCancel=true
			cancelSession(this.id).then(() => {
				this.$router.push({name: 'Sessions', state: {initialDate: this.startDate}});
			}).catch(() => {
				this.$toast.error('Annulation impossible')
			}).finally(() => {
				this.loadingCancel = false
			})
		},
		showOrEditSession(e) {
			if (this.recurrence !== 'ONCE') {
				e.preventDefault();
				this.askModifyWithRecurrence = true;
			} else
				this.editSession(e)
		},
		editSession(e) {
			if ( e !== undefined)
				e.preventDefault();
			if (this.checkForm()) {
				this.$store.dispatch({
					type: 'editSession',
					id: this.id,
					session: {
						startDate: this.startDate,
						endDate: this.endDate,
						startTime: this.startTime,
						endTime: this.endTime,
						recurrence: this.recurrence,
						animalIds: this.selectedAnimals.map(a => a.id),
						patientId: this.selectedPatient ? this.selectedPatient.id : null,
						clubId: this.selectedClub ? this.selectedClub.id : null,
						paid: this.paid,
						validated: true,
						price: this.price,
						roomRent: this.roomRent,
						animalRent: this.animalRent,
						taxesPaidByPatient: this.checkTaxesPaidByPatient ? this.taxesPaidByPatient : 0.0,
						comments: this.comments,
						patientPresent: this.patientPresent,
						withAnimalOnly: this.withAnimalOnly,
						therapistId: this.selectedTherapist ? this.selectedTherapist.id : null,
						selectedCorporatePatients: this.corporatePatientsDestination ? this.corporatePatientsDestination.map((obj) => obj.id) : null,
						sessionType: this.sessionType ? this.sessionType.id : null,
					},
					modificationType: this.modificationType
				}).then(()=> {
					this.$toast.success(`Séance mise à jour`);
					this.$router.push({name: 'Sessions', state: {initialDate: this.startDate}});
				}).catch((error) => {
					if (error.response.data.errorCode === 'E113') {
						this.errors.date = 'La date de fin doit être supérieure à la date de début.'
					}
				});
			}
		},
		changeSessionPrice() {
			this.price = this.computeSessionPrice()
		},
		computeSessionPrice() {
			let sessionPrice = null
			if (Object.keys(this.sessionType).length > 0 && this.sessionType.defaultSessionPrice !== null)
				sessionPrice = this.sessionType.defaultSessionPrice
			else
				sessionPrice = this.$store.state.user && this.$store.state.user.defaultSessionPrice != null ? this.$store.state.user.defaultSessionPrice : 0
			return sessionPrice
		},
		backToCalendar() {
			this.$router.push({name: 'Sessions', state: {initialDate: this.startDate}});
		},
		deleteSession() {
			this.deleteSessionsWithMessage(`Séance supprimée`);
		},
		deleteSessions() {
			this.deleteSessionsWithMessage(`Séances supprimées`);
		},
		deleteSessionsWithMessage(message) {
			this.$store.dispatch({
				type: 'deleteSessions',
				sessionInfos: {
					id: this.id,
					deletionType: this.deletionType
				}
			}).then(() => {
				this.backToCalendar();
				this.$toast.success(message);
			});
		},
		updateEndDate() {
			let tmpEndDate = new Date(this.startDate);
			tmpEndDate.setFullYear(tmpEndDate.getFullYear() + 1);
			this.endDate = tmpEndDate.toISOString().substring(0,10);
		},
		updateEndTime() {
			let tmpEndTime = new Date(this.startDate + " " + this.startTime);
			tmpEndTime.setHours(tmpEndTime.getHours()+1)
			this.endTime = tmpEndTime.toTimeString().substring(0,5);
		},
		removeAnimalActivity(animal) {
			_.remove(this.nbHoursLeft, elt=>elt.animalId === animal.id)
		},
		getAnimalActivity(animal) {
			this.$store.dispatch({
				type: 'getAnimalActivity',
				animal : {
					id: animal.id,
					selectedDate: this.startDate
				}
			}).then((response) => {
				this.nbHoursLeft.push({
					hours : response.nbHoursPlanned - response.nbHoursDoneCurrentWeek,
					animalId: animal.id,
					animalName: animal.name
				})
			})
		},
		addSelected(e) {
			e.preventDefault();
			if (Object.keys(this.selectedCorporatePatientsOrigin).length === 0) return;
			
			//drop from origin
			let patientsOrigin = this.selectedCorporatePatientsOrigin;
			this.corporatePatientsOrigin = this.corporatePatientsOrigin.filter((value) => {
				return !patientsOrigin.includes(value);
			});

			//add to destination
			let patientsDestination = this.corporatePatientsDestination;
			let patientsNotAlreadyPresent = this.selectedCorporatePatientsOrigin.filter((value) => {
				return !patientsDestination.includes(value);
			});
			if (this.corporatePatientsDestination === null)
				this.corporatePatientsDestination = [];
			this.corporatePatientsDestination.push(...patientsNotAlreadyPresent);
		},
		addAll(e) {
			e.preventDefault();
			if (this.corporatePatientsDestination === null)
				this.corporatePatientsDestination = [];
			this.corporatePatientsDestination.push(...this.corporatePatientsOrigin);
			this.corporatePatientsOrigin = [];
		},
		removeSelected(e) {
			e.preventDefault();
			if (Object.keys(this.selectedCorporatePatientsDestination).length === 0) return;
			//drop from destination
			let patientDestination = this.selectedCorporatePatientsDestination;
			this.corporatePatientsDestination = this.corporatePatientsDestination.filter(function(value){ 
				return !patientDestination.includes(value);
			});

			//add to origin
			let patientsOrigin = this.corporatePatientsOrigin;
			let patientsNotAlreadyPresent = this.selectedCorporatePatientsDestination.filter((value) => {
				return !patientsOrigin.includes(value);
			});
			this.corporatePatientsOrigin.push(...patientsNotAlreadyPresent);
		},
		removeAll(e) {
			e.preventDefault();
			if (this.corporatePatientsOrigin === null)
				this.corporatePatientsOrigin = [];
			this.corporatePatientsOrigin.push(...this.corporatePatientsDestination);
			this.corporatePatientsDestination = [];
		},
		playAudio(audioComment) {
			getAudioCommentBySessionId(this.id, audioComment.name)
				.then((response) => {
					let audioContent = this.stringToBuffer(response.content);
					const audioBlob = new Blob([audioContent], {type: 'audio/webm'});
					const audioUrl = URL.createObjectURL(audioBlob);
					const audio = new Audio(audioUrl);
					audio.play();
				})
				.catch(() => {});
			
		},
		deleteAudioContent(audioComment) {
			deleteAudioCommentById(this.id, audioComment.name)
			.then(()=> {
				this.audioComments.pop(audioComment);
			});
		},
		getDateFromName(audioCommentName) {
			let audioDate = new Date(Number(audioCommentName));
			return audioDate.toLocaleDateString() + ' ' + audioDate.toLocaleTimeString()
		},
		stringToBuffer(value) {
			let buffer = new ArrayBuffer(value.length * 2); // 2 bytes per char
			let view = new Uint8Array(buffer);
			for (let i = 0, length = value.length; i < length; i++) {
				view[i] = value.charCodeAt(i);
			}
			return buffer;
		},
		updateAudioComments(audioComment) {
			this.audioComments.push(audioComment);
		},
		displayRecurrence() {
			let rec = this.recurrences.find(r => r.value === this.recurrence);
			if (this.recurrence === "WEEK") {
				let day = this.weekday[new Date(this.startDate).getDay()];
				return 'toutes les semaines le ' + day;
			} else if (this.recurrence === "TWO_WEEKS") {
				let day = this.weekday[new Date(this.startDate).getDay()];
				return 'toutes les deux semaines le ' + day;
			}

			return rec.description;
		},
		changeTaxesValue() {
			this.roomRent = this.selectedClub.amountPerSession ? this.selectedClub.amountPerSession : 0;
			this.updatetaxesPaidByPatient();
		},
		updatetaxesPaidByPatient() {
			if (this.checkTaxesPaidByPatient) {
				this.taxesPaidByPatient = this.roomRent + this.animalRent;
			} else
				this.taxesPaidByPatient = 0.0;
		}
	}
})
</script>
<style scoped>
.session-section {
	margin-top: 2rem !important;
}

#select-corporate-participants {
	display: flex;
}

.select-multiple-patients {
	min-height: 10rem;
}

.modal-title-with-delete {
    display: flex;
}

#work-with-animal {
	display: flex;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

#sessionDetails {
    margin: 2rem;
}

.beforeDelete {
    margin-right: 10rem;
}

.play-icon-container {
	margin-right: 1rem;
}
.date-audio-container {
	min-width: 12rem;	
}
.delete-sound-icon-container {
	margin-left: 1rem;
}

.delete-event {
	margin-top: 1rem;
}

.event-date {
	width: 8rem !important;
}


#event-patient-animal-club-values {
	display: flex;
}

#event-patient-animal-club-values div {
	margin-right: 1rem;
}

.date-time-input {
	display: flex;
}

.delete-session-text-intro {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 2rem;
}

.delete-session-radio-selection {
	margin-bottom: 0.5rem;
}

.delete-session-radio-selection input {
	margin-right: 0.5rem;
}

.delete-session-buttons {
	margin-top: 1rem;
}

.nb-hours-left-container {
	border-radius: 5px;
	background-color: var(--warning-box);
	max-width: 30rem;
	padding: 1rem;
	color: #886402;
}
.nb-hours-left-container ul {
	margin-left: 2rem;
	color: 836e28;
}

.recurrence {
	margin-left: 2rem;
}
.text-area {
	width: 40rem;
    height: 10rem;
}

@media (max-width: 800px) {
	.date-time-input {
		display: block;
	}

	#event-patient-animal-club-values {
		display: block;
	}

	#event-patient-animal-club-values {
		display: block;
	}

	.text-area {
		max-width: 20rem;
		max-height: 8rem;
	}
}
</style>	